var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center mb-3"},[_c('h3',{staticClass:"text-h4 font-weight-light"},[_vm._v("Order P&L")]),_c('div',{staticClass:"d-inline-flex align-center"},[_c('v-btn',{attrs:{"icon":"","small":!_vm.$vuetify.breakpoint.lgAndUp,"color":"grey"}},[_c('v-icon',[_vm._v("mdi-filter-outline")])],1),_c('v-btn',{attrs:{"icon":"","small":!_vm.$vuetify.breakpoint.lgAndUp,"color":"grey"},on:{"click":function($event){return _vm.resetTable()}}},[_c('v-icon',[_vm._v("mdi-cached")])],1)],1)]),_c('div',[(!_vm.reset)?_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-text',[_c('v-data-table',{staticClass:"fsk-v-data-table",attrs:{"headers":_vm.headers,"items":_vm.table,"search":_vm.search['input']},scopedSlots:_vm._u([{key:"item.lead_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-3"},[_c('div',[_vm._v(_vm._s(item['lead_name']))]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item['lead_company']))])])]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item['cost']))+" ")]}},{key:"item.earning",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item['earning']))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"x-small":"","outlined":""}},[_vm._v(_vm._s(_vm.numberWithCommas(Math.abs(item['cost'] - item['earning']))))])],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$moment.utc(item['date']).format('DD MMM YYYY')))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"v-data-table-actions"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-icon',{staticClass:"mr-0"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("View Order")])],1)],1),_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-icon',{staticClass:"mr-0"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-comment-processing")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("View Comments")])],1)],1)],1)],1)],1)]}}],null,true)})],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }