<template>
	<div>
		<!-- Page Header -->
		<div class="d-flex justify-space-between align-center mb-3">
			<h3 class="text-h4 font-weight-light">Order P&L</h3>

			<div class="d-inline-flex align-center">
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey"><v-icon>mdi-filter-outline</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey" @click="resetTable()"><v-icon>mdi-cached</v-icon></v-btn>
			</div>
		</div>

		<!-- Page Body -->
		<div>
			<v-card :loading="loading" v-if="!reset">
				<v-card-text>
					<v-data-table
						class="fsk-v-data-table"
						:headers="headers"
						:items="table"
						:search="search['input']"
					>
						<template v-slot:[`item.lead_name`]="{ item }">
							<div class="py-3">
								<div>{{ item['lead_name'] }}</div>
								<div class="caption">{{ item['lead_company'] }}</div>
							</div>
						</template>
						<template v-slot:[`item.cost`]="{ item }">
							{{ numberWithCommas(item['cost']) }}
						</template>
						<template v-slot:[`item.earning`]="{ item }">
							{{ numberWithCommas(item['earning']) }}
						</template>
						<template v-slot:[`item.status`]="{ item }">
							<div class="text-center">
								<v-chip :color="getStatusColor(item.status)" x-small outlined>{{ numberWithCommas(Math.abs(item['cost'] - item['earning'])) }}</v-chip>
							</div>
						</template>
						<template v-slot:[`item.date`]="{ item }">
							<div>{{ $moment.utc(item['date']).format('DD MMM YYYY') }}</div>
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<div class="v-data-table-actions">
								<v-menu bottom left>
									<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
										<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
									</template>

									<v-list>
									<v-list-item dense @click="editItem(item)">
										<v-list-item-icon class="mr-0"><v-icon small>mdi-file</v-icon></v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>View Order</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item dense>
										<v-list-item-icon class="mr-0"><v-icon small>mdi-comment-processing</v-icon></v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title>View Comments</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			modal: {
				date: false
			},
			search: {
				show: false,
				input: ''
			},
			loading: false,
			reset: false,
			headers: [
				{
					text: 'Name | Company',
					align: 'start',
					value: 'lead_name',
				},
				{
					text: 'Date',
					value: 'date',
				},
				{
					text: 'Order No.',
					value: 'order_no',
				},
				{
					text: 'Product / Service',
					value: 'rows[0].inventory',
				},
				{
					text: 'Cost',
					value: 'cost',
				},
				{
					text: 'Earning',
					value: 'earning',
				},
				{
					text: 'P&L',
					value: 'status',
					align: 'center',
					sortable: false,
				},
				{ text: 'Actions', align: 'end', value: 'actions', sortable: false },
			],
			table: [],
		}),

		created() {
			this.initialize();
		},

		methods: {
			initialize () {
				this.table = [
					{
						id: 1,
						order_no: 'FSK0001',
						lead_name: 'Charles Cooper',
						lead_company: 'Hangar Pvt. Ltd',
						lead_phone: '9231457895',
						lead_email: 'charles@hangar.com',
						rows: [
							{
								inventory: 'Website',
								amount: '45,000',
								quantity: '1',
							}
						],
						sub_total: '45,000',
						tax: '',
						discount: '',
						cost: 45000,
						earning: 85000,
						status: 'Profit',
						date: new Date()
					},
					{
						id: 2,
						order_no: 'FSK0002',
						lead_name: 'Stephen Clark',
						lead_company: 'Smart Ltd',
						lead_phone: '9231457895',
						lead_email: 'stephen@smart.com',
						rows: [
							{
								inventory: 'Domain + Hosting',
								amount: '1,65,000',
								quantity: '1',
							}
						],
						sub_total: '1,65,000',
						tax: '',
						discount: '',
						cost: 165000,
						earning: 100000,
						status: 'Loss',
						date: new Date(Date.now() - 86400000)
					},
					{
						id: 3,
						order_no: 'FSK0003',
						lead_name: 'Russell Lee',
						lead_company: 'Rus Corp',
						lead_phone: '9231451423',
						lead_email: 'russell@rcorp.com',
						rows: [
							{
								inventory: 'Instagram Ads',
								amount: '97,000',
								quantity: '1',
							}
						],
						sub_total: '97,000',
						tax: '',
						discount: '',
						cost: 97000,
						earning: 200000,
						status: 'Profit',
						date: new Date(Date.now() - 3888000000)
					},
					{
						id: 4,
						order_no: 'FSK0004',
						lead_name: 'Rachel Wood',
						lead_company: 'CC Corp.',
						lead_phone: '9212678992',
						lead_email: 'rachel@ccc.com',
						rows: [
							{
								inventory: 'Website',
								amount: '2,50,000',
								quantity: '1',
							}
						],
						sub_total: '2,50,000',
						tax: '',
						discount: '',
						cost: 250000,
						earning: 500000,
						status: 'Profit',
						date: new Date(Date.now() - 6480000000)
					},
					{
						id: 5,
						order_no: 'FSK0005',
						lead_name: 'Joel Hill',
						lead_company: 'WebTech',
						lead_phone: '9212678992',
						lead_email: 'joelbhill@rhyta.com',
						rows: [
							{
								inventory: 'Website',
								amount: '2,45,000',
								quantity: '1',
							}
						],
						sub_total: '2,45,000',
						tax: '',
						discount: '',
						cost: 245000,
						earning: 300000,
						status: 'Profit',
						date: new Date(Date.now() - 7344000000)
					},
				]
			},
			resetTable() {
				this.loading = true;

				setTimeout(() => {
					this.loading = false;
					this.reset = true;
					this.reset = false;
				}, 3000);
			},
			getStatusColor (status) {
				switch (status) {
					case 'Profit':
						return 'success'
					
					case 'Loss':
						return 'red'
			
					default:
						return 'grey'
				}
			},
			editItem(item) {
				console.log(item);
			}
		}
	}
</script>
